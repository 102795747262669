html {
    height: 100%;
    width: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    overscroll-behavior: none;
    position: fixed;
    overflow: hidden;
}

html, body {
    overscroll-behavior-y: contain;
}

#root {
    width: 100%;
    height: 100%;
}
